// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  envName: 'development',
  oldAppURL: 'https://test-developer.fisglobal.com',
  // apiHost: 'https://ga9v6qfmm5.execute-api.us-east-2.amazonaws.com/dev',
  // apiKey: 'y5r8B8SVWg6C44PbSbuDh9CDf4uwOw62aWGK8KgO',
  // sandboxApiHost: 'https://ga9v6qfmm5.execute-api.us-east-2.amazonaws.com/dev',
  // sandboxApiKey: 'y5r8B8SVWg6C44PbSbuDh9CDf4uwOw62aWGK8KgO',
  apiHost: 'https://test-developer.fisglobal.com',
  apiKey: '7htms2jKCO221ES39CnOt9B4Bg5nkyTW3bH01Uqa',
  selfcertApiHost: "https://test-eapi.fisglobal.com",
  sandboxApiHost: 'https://dev-docs.worldpay.com',
  sandboxApiKey: 'vmdNN2iIawafp5619E8tJ4PNrSCeY4bMaKlZHIjG',
  UserPoolId: 'us-east-2_8ZGttwqaO',
  AppWebDomain: 'https://dev-developer-fisglobal.auth.us-east-2.amazoncognito.com',
  ClientId: 'b9sqps58k6s6o3ebmu1mdtaus',
  RedirectUriSignIn: 'https://dev-docs.worldpay.com/index.html',
  RedirectUriSignOut: 'https://dev-docs.worldpay.com/index.html',
  TokenScopesArray: ['email', 'phone', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
  instanaKey: '',
  logoutLink:
    'https://dev-developer-fisglobal.auth.us-east-2.amazoncognito.com/logout?logout_uri=https://dev-docs.worldpay.com/index.html&client_id=b9sqps58k6s6o3ebmu1mdtaus',
  hotjar: '',
  googleAnalytics: '',
  googleAnalyticsUrl: 'https://www.googletagmanager.com/gtag/js?id=',
  content: '../../../catalyst-content/content',
  pendoApiKey: '',
  pendoAccountId: '',
  chatEnabled: true,
  chatUrl: '//worldpayusgw.whoson.com/include.js?domain=developerengine.fisglobal.com',
  chatTokenInterval: 190000,
  // Salesforce web to lead form configurations
  sfLeadSubmissionURL: 'https://CS69.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8', // salesforce web to lead form submission url
  sfOrgId: '00DVB000002Kc4n', // salesforce web to lead configuration for organization Id
  sfCampaignID: '7013h000000ULBBAA4', // salesforce web to lead configuration for campaign Id
  sfRecordType: '0123h000000MnxaAAC',
  marketoCodeId: '975-BCU-707',
  marketoCodeSrcUrl: '//munchkin.marketo.net/munchkin.js',
  // One Trust Configuration
  functionalCookiedId: 'optanon-category-C0003',
  marketingCookieId: 'optanon-category-C0004',
  performanceCookiedId: 'optanon-category-C0002',
};
